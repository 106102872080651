import { xenn } from './index';

export default {
  cardinality(options) {
    return xenn.post('/subscriber/cardinality', options);
  },
  terms(options) {
    return xenn.post('/subscriber/terms', options);
  },
  dateHistogram(options) {
    return xenn.get('/analytics/subscriber-event-growth-by-interval', { params: options });
  },
  lastSeenDays(options) {
    return xenn.get('/analytics/subscriber-last-seen-days', { params: options });
  },
  termsCardinality(options) {
    return xenn.post('/subscriber/terms-cardinality', options);
  },
  count(options, key) {
    return xenn.get('analytics/subscriber-count', { params: options });
  },
  subscribers(params) {
    return xenn.get('/subscribers', { params: params });
  },
  dailySubscriptionStart(params) {
    return xenn.get('/daily-subscription-start', { params: params });
  },
  subscribersCount() {
    return xenn.get('/subscribers-count');
  },
  activeSubscribersCount() {
    return xenn.get('/active-subscribers-count');
  },
  pushTokensCount() {
    return xenn.get('/push-tokens-count');
  },
  emailsCount() {
    return xenn.get('/emails-count');
  },
  smsCount() {
    return xenn.get('/sms-count');
  },
  tags() {
    return xenn.get('/tags');
  },
  subscriber(id) {
    return xenn.get(`/subscribers/${id}`, {});
  },
  updateSubscriber(id, data) {
    return xenn.patch(`/subscribers/${id}`, data);
  },
  members(params) {
    return xenn.get('/members', { params: params });
  },
  statistics() {
    return xenn.get('members/statistics', {});
  },
  get(id) {
    return xenn.get(`/members/${id}`, {});
  },
  sessions(id) {
    return xenn.get(`/members/${id}/devices`, {});
  },
  browsingHistory(id, sdkKey, entityName) {
    return xenn.get('/browsing-history', {
      params: {
        sdkKey: sdkKey,
        size: 50,
        entityName: entityName,
        memberId: id,
      },
    });
  },
  pushMessages(memberId) {
    return xenn.get('/push-messages', {
      params: {
        size: 50,
        memberId: memberId,
      },
    });
  },
};
