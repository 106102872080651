<template>
  <div v-if="detail" class="qulert-widgets-stats">
    <stats-card :title="detail.title" :sub-title="detail.values.main" :type="detail.type" :icon="detail.icon">
      <template slot="footer">
        <div v-if="detail.values.sub">
          <span :class="`${detail.values.sub.style} mr-2`">{{ subValue | formatNumeric }}</span>
          <span class="text-nowrap">{{ detail.values.sub.label }}</span>
        </div>
        <div v-else>&nbsp;</div>
      </template>
    </stats-card>
  </div>
</template>

<script>
import StatsCard from '../../../Cards/StatsCard';
import memberService from '@/services/members';
import { formatNumeric } from '@/utils/format';
import variants from './variants';

export default {
  name: 'QulertWidgetsStats',
  components: {
    StatsCard,
  },
  props: {
    variant: {
      type: String,
    },
    subValue: {
      type: String,
    },
    details: {
      type: Object,
    }
  },
  watch: {
    variant: {
      immediate: true,
      handler(val) {
        if (val) {
          this.detail = variants[val];
          this.runQuery();
        } else if (this.details) {
          this.detail = this.details;
        }
      }
    },
  },
  data: () => ({
    variants,
    detail: null,
  }),
  methods: {
    async runQuery() {
      const res = await memberService.count(this.detail.query);
      this.detail.values.main = formatNumeric(res.count);
    }
  },
};
</script>
