<template>
  <div class="qulert-charts-appLaunchHour">
    <card type="default" header-classes="bg-transparent">
      <b-row align-v="center" slot="header">
        <b-col>
          <h6 class="text-light text-uppercase ls-1 mb-1">Last 30 Days</h6>
          <h5 class="h3 text-white mb-0">App Launch Hour</h5>
        </b-col>
      </b-row>
      <div class="chart" style="margin-bottom: -30px;">
        <line-chart v-if="chartData.datasets.length" :height="300" :chart-data="chartData" />
      </div>
    </card>
  </div>
</template>

<script>
import LineChart from '@/components/Charts/LineChart';
import memberService from '@/services/members';
import { beforeNowTillStartSecond, now } from '@/utils/date';

const last30 = beforeNowTillStartSecond({ days: 30 });

export default {
  name: 'QulertAppLaunchHour',
  components: {
    LineChart,
  },
  created() {
    this.loadChart();
  },
  data: () => ({
    sum: {},
    chartData: {
      datasets: [],
      labels: [],
    },
  }),
  methods: {
    async loadChart() {
      const res = await memberService.dateHistogram({
        field: 'ls',
        interval: 'Hour',
        startDate: last30,
        endDate: now(),
      });

      res.forEach((row) => {
        const key = row.dateAsString.split('T')[1].slice(0, 2);
        this.sum[key] = (this.sum[key] || 0) + row.value;
      });

      const set = Object.entries(this.sum).sort((a, b) => parseInt(a) - parseInt(b));

      this.chartData = {
        datasets: [{
          data: set.map(s => s[1]),
          lineTension: 0,
          borderColor: '#f5365c',
          pointBackgroundColor: '#f5365c',
        }],
        labels: set.map(s => s[0]),
      };
    }
  },
};
</script>
