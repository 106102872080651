<template>
  <card type="default" header-classes="bg-transparent">
    <b-row align-v="center" slot="header">
      <b-col>
        <h6 class="text-light text-uppercase ls-1 mb-1">Overview</h6>
        <h5 class="h3 text-white mb-0">Subscriber Growth</h5>
      </b-col>
    </b-row>
    <div class="chart" style="margin-bottom: -30px;">
      <line-chart v-if="chartData.datasets.length" :height="300" :chart-data="chartData" />
    </div>
  </card>
</template>

<script>
import LineChart from '@/components/Charts/LineChart';
import memberService from '../../services/members';
import { beforeNowTillStartSecond, formatISODateShort } from '@/utils/date';

export default {
  name: 'subscriberGrowth',
  mounted() {
    this.loadData();
  },
  components: {
    LineChart,
  },
  props: ['lastDayNewSubscriberCount'],
  data: () => ({
    chartData: {
      datasets: [],
      labels: [],
    },
  }),
  methods: {
    async loadData() {
      const allCount = await memberService.count();
      const total = allCount.count;
      const d = await memberService.dateHistogram({
        field: 'fs',
        startDate: beforeNowTillStartSecond({ days: 30 }),
        endDate: beforeNowTillStartSecond({ days: 0 }),
      });
      const labels = [];
      const values = [];
      let growthOverTime = 0;
      let counter = 0;

      d.reverse()
        .forEach((eachBucket) => {
          growthOverTime += eachBucket.value;
          labels.push(formatISODateShort(eachBucket.dateAsString));
          values.push(total - growthOverTime);
          if (counter === d.length - 1) {
            this.lastDayNewSubscriberCount.count = eachBucket.value;
          }
          counter++;
        });

      this.chartData = {
        datasets: [{
          data: values.reverse(),
        }],
        labels: labels.reverse(),
      };
    },
  },
};
</script>
