<template>
  <div>
    <base-header class="pb-6">
      <b-row aling-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
        <b-col cols="5" lg="6" class="text-right">
        </b-col>
      </b-row>

      <div class="qulert-widgets-stats-wrapper mb-4">
        <Stats variant="totalSubscribers" :subValue="lastDayNewSubscriberCount.count" />
        <Stats variant="activeSubscribers" />
        <Stats variant="newSubscribers" />
        <Stats variant="pushTokens" />
      </div>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col md="6">
          <subscriber-growth :last-day-new-subscriber-count="lastDayNewSubscriberCount"></subscriber-growth>
        </b-col>
        <b-col md="6">
          <chart-app-actions />
        </b-col>
        <b-col md="6">
          <chart-app-launch-day />
        </b-col>
        <b-col md="6">
          <chart-app-launch-hour />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import memberService from '../../services/members';
import SubscriberGrowth from '../../components/subscribers/subscriber-growth';
import { FilterQuery } from '@/models/filterQuery';
import { beforeNowTillStartSecond } from '@/utils/date';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
import Stats from '../../components/qulerts/widgets/stats/index';
import ChartAppActions from '../../components/qulerts/charts/appActions/index';
import ChartAppLaunchDay from '../../components/qulerts/charts/appLaunchDay/index';
import ChartAppLaunchHour from '../../components/qulerts/charts/appLaunchHour/index';

export default {
  name: 'homePage',
  components: {
    SubscriberGrowth,
    RouteBreadCrumb,
    Stats,
    ChartAppActions,
    ChartAppLaunchDay,
    ChartAppLaunchHour,
  },
  data: () => ({
    subscribersCount: 0,
    activeSubscribersCount: 0,
    pushTokensCount: 0,
    emailCount: 0,
    phoneCount: 0,
    lastDayNewSubscriberCount: { count: 0 },
  }),
  methods: {
    async getStatistics() {
      const activeCount = await memberService.count(
        new FilterQuery()
          .addRange('fs', {
            gte: beforeNowTillStartSecond({ days: 30 }),
          })
          .build(),
      );
      const allCount = await memberService.count(new FilterQuery().build());
      const pushCount = await memberService.count(new FilterQuery()
        .exists('tk')
        .build());
      const emailsCount = await memberService.count(new FilterQuery()
        .exists('em')
        .build());
      const smsCount = await memberService.count(new FilterQuery()
        .exists('ph')
        .build());
      this.$set(this, 'subscribersCount', allCount.count);
      this.$set(this, 'activeSubscribersCount', activeCount.count);
      this.$set(this, 'pushTokensCount', pushCount.count);
      this.$set(this, 'emailCount', emailsCount.count);
      this.$set(this, 'phoneCount', smsCount.count);
    },
  },
};
</script>
