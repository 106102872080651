import { beforeNowTillStartSecond, now } from '@/utils/date';

const last30 = beforeNowTillStartSecond({ days: 30 });
const variants = {
  totalSubscribers: {
    title: 'Total Users',
    values: {
      main: '-',
      sub: {
        style: 'text-success',
        label: 'Since last day',
      },
    },
    type: 'success',
    icon: 'people-fill',
    query: [],
  },
  activeSubscribers: {
    title: 'Active Users',
    values: {
      main: '-',
    },
    type: 'primary',
    icon: 'play-fill',
    query: {
      field: 'ls',
      startDate: last30,
      endDate: now(),
    },
  },
  newSubscribers: {
    title: 'New Users',
    values: {
      main: '-',
    },
    type: 'gradient-info',
    icon: 'star-fill',
    query: {
      field: 'fs',
      startDate: last30,
      endDate: now(),
    },
  },
  pushTokens: {
    title: 'Push Tokens',
    values: {
      main: '-',
    },
    type: 'red',
    icon: 'app-indicator',
    query: {
      field: 'tk',
    },
  },
};

export default variants;
