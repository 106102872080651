<template>
  <div class="qulert-charts-appActions">
    <card type="default" header-classes="bg-transparent">
      <b-row align-v="center" slot="header">
        <b-col>
          <h6 class="text-light text-uppercase ls-1 mb-1">Last 30 Days</h6>
          <h5 class="h3 text-white mb-0">App Actions</h5>
        </b-col>
      </b-row>
      <div class="chart" style="margin-bottom: -30px;">
        <line-chart v-if="chartData.datasets.length" :height="300" :chart-data="chartData"
          :extra-options="{ legend: { display: true, position: 'top' } }" />
      </div>
    </card>
  </div>
</template>

<script>
import LineChart from '@/components/Charts/LineChart';
import memberService from '@/services/members';
import { beforeNowTillStartSecond, formatISODateShort, yesterdayEOD } from '@/utils/date';

const last30 = beforeNowTillStartSecond({ days: 30 });
export default {
  name: 'QulertAppActions',
  components: {
    LineChart,
  },
  created() {
    this.loadChart();
  },
  data: () => ({
    sum: {},
    chartData: {
      datasets: [],
      labels: [],
    },
  }),
  methods: {
    loadChart() {
      Promise.all([
        memberService.dateHistogram({
          field: 'ls',
          startDate: last30,
          endDate: yesterdayEOD(),
        }),
        memberService.dateHistogram({
          field: 'fs',
          startDate: last30,
          endDate: yesterdayEOD(),
        }),
      ])
        .then(([launch, subs]) => {
          const labels = launch.map((row) => formatISODateShort(row.dateAsString));

          this.chartData = {
            datasets: [{
              label: 'Launch',
              data: launch.map(row => row.value),
              borderColor: '#11cdef',
              pointBackgroundColor: '#11cdef',
            }, {
              label: 'Subscribers',
              data: subs.map(row => row.value),
              borderColor: '#f4f5f7',
              pointBackgroundColor: '#f4f5f7',
            }],
            labels,
          };
        });
    }
  },
};
</script>
