export class FilterQuery {
  constructor() {
    this.exactMatchValues = new Map();
    this.multiMatchValues = new Map();
    this.rangeQueries = new Map();
    this.existsQueries = [];
    this.aggregation = {};
  }

  clone() {
    const filterQuery = new FilterQuery();
    filterQuery.exactMatchValues = Object.assign(new Map(), this.exactMatchValues);
    filterQuery.multiMatchValues = Object.assign(new Map(), this.multiMatchValues);
    filterQuery.rangeQueries = Object.assign(new Map(), this.rangeQueries);
    filterQuery.aggregation = Object.assign({}, this.aggregation);
    return filterQuery;
  }

  getVisibleFilters() {
    const filters = [];
    this.exactMatchValues.forEach((value, key) => {
      if (key !== 'n' && key !== 'paid') {
        filters.push({
          key: key,
          value: value,
          display: `${key}=${value}`,
        });
      }
    });
    this.multiMatchValues.forEach((value, key) => {
      value.forEach((eachValue) => {
        if (key !== 'n' && key !== 'paid') {
          filters.push({
            key: key,
            value: eachValue,
            display: `${key}=${eachValue}`,
          });
        }
      });
    });

    return filters;
  }

  addRange(field, rangeExp) {
    this.rangeQueries.set(field, rangeExp);
    return this;
  }

  exists(field) {
    this.existsQueries.push(field);
    return this;
  }

  removeMatch(field, value) {
    if (this.exactMatchValues.has(field)) {
      this.exactMatchValues.delete(field);
    } else if (this.multiMatchValues.has(field)) {
      const existingFilter = this.multiMatchValues.get(field);
      const filteredFilter = [];
      existingFilter.forEach((filterValue, key) => {
        if (key !== field && filterValue !== value) {
          filteredFilter.push(filterValue);
        }
      });
      this.multiMatchValues.set(field, filteredFilter);
    }
    return this;
  }

  addMatch(field, value) {
    if (this.exactMatchValues.has(field)) {
      const existingValue = this.exactMatchValues.get(field);
      if (existingValue !== value) {
        const existingFilter = [existingValue];
        existingFilter.push(value);
        this.multiMatchValues.set(field, existingFilter);
        this.exactMatchValues.delete(field);
      }
    } else if (this.multiMatchValues.has(field)) {
      const existingFilter = this.multiMatchValues.get(field);
      let isFilterAppliedWithSameValue = false;
      existingFilter.forEach((element) => {
        if (element === value) {
          isFilterAppliedWithSameValue = true;
        }
      });
      if (!isFilterAppliedWithSameValue) {
        existingFilter.push(value);
        this.multiMatchValues.set(field, existingFilter);
      }
    } else {
      this.exactMatchValues.set(field, value);
    }
    return this;
  }

  setAggregation(agg) {
    this.aggregation = agg;
    return this;
  }

  build() {
    const query = {
      bool: {
        must: [],
      },
    };

    this.exactMatchValues.forEach((value, key) => {
      const term = {};
      term[key] = { value: value };
      query.bool.must.push({ term: term });
    });

    this.rangeQueries.forEach((value, key) => {
      const range = {};
      range[key] = value;
      query.bool.must.push({ range: range });
    });

    this.existsQueries.forEach((key) => {
      query.bool.must.push({ exists: { field: key } });
    });

    this.multiMatchValues.forEach((value, key) => {
      const bool = { should: [] };
      value.forEach((eachValue) => {
        const term = {};
        term[key] = { value: eachValue };
        bool.should.push({ term: term });
      });
      query.bool.must.push({ bool: bool });
    });

    return {
      query: query,
      aggregation: this.aggregation,
    };
  }
}
