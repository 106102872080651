<template>
  <div class="qulert-charts-appLaunchDay">
    <card type="default" header-classes="bg-transparent">
      <b-row align-v="center" slot="header">
        <b-col>
          <h6 class="text-light text-uppercase ls-1 mb-1">Last 30 Days</h6>
          <h5 class="h3 text-white mb-0">App Launch Day</h5>
        </b-col>
      </b-row>
      <div class="chart" style="margin-bottom: -30px;">
        <bar-chart v-if="chartData.datasets.length" :height="300" :chart-data="chartData" />
      </div>
    </card>
  </div>
</template>

<script>
import BarChart from '@/components/Charts/BarChart';
import memberService from '@/services/members';
import { beforeNowTillStartSecond, now } from '@/utils/date';

const last30 = beforeNowTillStartSecond({ days: 30 });

const daysMap = {
  'Monday': 0,
  'Tuesday': 1,
  'Wednesday': 2,
  'Thursday': 3,
  'Friday': 4,
  'Saturday': 5,
  'Sunday': 6,
};

export default {
  name: 'QulertAppLaunchDay',
  components: {
    BarChart,
  },
  created() {
    this.loadChart();
  },
  data: () => ({
    sum: {},
    chartData: {
      datasets: [],
      labels: [],
    },
  }),
  methods: {
    async loadChart() {
      const res = await memberService.lastSeenDays({
        startDate: last30,
        endDate: now(),
      });

      const set = res.sort((a, b) => daysMap[a.key] - daysMap[b.key]);

      this.chartData = {
        datasets: [{
          data: set.map(s => s.value),
        }],
        labels: set.map(s => s.key),
      };
    }
  },
};
</script>
